import React, { useState, useEffect } from "react";
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import { Row, Col, CardBody, Input, Badge, Button } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { dateyyyymmdd_tohtml } from "../../helpers/date";
// import { useSelector } from "react-redux/es/hooks/useSelector";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import Pagination from "../../components/Common/Pagination"
import { Link } from "react-router-dom";
// import QuestionMasterOffCanvas from "../../components/questionMaster/QuestionMasterOffCanvas";
import QuestionMasterOffCanvas from "../../components/questionMaster/QuestionMasterOffCanvas";
import CustomTable from "../../common/CustomTable";
import moment from "moment";
import { current } from "@reduxjs/toolkit";
import TableLoader from "../../components/Common/TableLoader";


function QuestionMaster() {
  // const addTodo=useSelector(state=>state.todos)

  // const [copyData, setCopyData] = useState([])
  // const [copied, setCopied] = useState(false)
  const [questionmaster, setQuestion_master] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);
  // const [state, setState] = useState("");
  const [gu_id, setGu_id] = useState("");
  const [isRight, setIsRight] = useState(false);
  const [searchedWord, setSearchedWord] = useState("");
  const [currentPage, setCurrentPage] = useState(0);


  let SearchedValue = "";

  // const [isLoading, setIsLoading] = useState(true);

  // const [tableData, setTableData] = useState([])
  const columns = [
    {
      Header: "Question ",
      accessor: "question",
      Cell: ({ row }) => (  <Link
        to="#"
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() =>
          (row.original.type === "behavioural" ? "" : (toggleRightCanvas(row.original.guid)))
        }
      >
        {row.original.question}
      </Link>
        // <ReadMoreComponent
        //   // key={index}
        //   text={row.original.question}
        //   maxWords={maxWords}
        //   toggleFunc={toggleRightCanvas}
        //   rowData={row}
        // />
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) =>
      <div className="hstack">
        <Badge className="rounded-pill bg-primary-subtle text-muted">{row.original.type}</Badge>
        <div>
       { row.original.skills.length !== 0
          ? row.original.skills.map((skill) => (
              <Badge className="rounded-pill ms-2 bg-info-subtle text-muted">{skill}</Badge>
            ))
          : ""}
        </div>
      </div> ,
    },
  
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM, YYYY"),
    },
 
  ];
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      `https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/questioncorpus?limit=10&offSet=${currentPage}`,
      (_question_corpus_err, _question_corpus_res) => {
       //document.getElementById("preloader").style.display = "block";
        if (_question_corpus_res) {
          setQuestion_master(_question_corpus_res.questioncorpuses);
          setIsPreloader(false);
        }
      },
    );
  }, [currentPage])
  

  function handleSearchedVal(event) {
    SearchedValue = event.target.value;
  }

  function toggleRightCanvas(id) {
    setGu_id(id);
    setIsRight(!isRight);
  }

  const handleClick = (event) => {
    setSearchedWord(SearchedValue);
    // 👇️ value of input field
  };
  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      // Perform your search logic here
      handleClick();
    }
  };

  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      `https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/questioncorpus?search_query=${searchedWord}&limit=10`,
      (_question_corpus_err, _question_corpus_res) => {
        //document.getElementById("preloader").style.display = "block";
        if (_question_corpus_res) {
          setQuestion_master(_question_corpus_res.questioncorpuses);
          setIsPreloader(false);
        }
      },
    );
  }, [searchedWord]);
  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);

  //meta title
  // document.title = "Responsive Table | Minia - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      {!isPreloader && (
        <div className="page-content">
          <div className="container-fluid">
            {/* <div className="hstack gap-3 d-flex flex-row-reverse mb-2 mt-0">
              <Input
                onChange={(e) => setState(e.target.value)}
                className="form-control me-auto"
                type="text"
                style={{ width: "30%", marginLeft: "655px" }}
                placeholder="Search records here..."
              />
             
            </div> */}
            <Breadcrumbs title="Question Corpus" />
            <Row>
            <div className="d-flex">
              <Col lg={4} md={6}>
                <div className="mb-2 me-2">
                  <Input
                    onChange={handleSearchedVal}
                    onKeyDown={handleSearchOnEnter}
                    className="form-control"
                    type="text"
                    id="searchedWord"
                    placeholder="Global filter..."
                  />
                </div>
              </Col>
             
              <Col lg={1} md={6}>
                <Button onClick={handleClick} className="ms-2 bg-primary">
                  <i className="fa fa-search me-2"> </i>Search
                </Button>
              </Col>
            </div>
              <Col>
                {/* <Card> */}

                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {questionmaster.length > 0 ?  <CustomTable
                       currentPage={currentPage}
                       onPageChange={handlePageChange}
                       data={questionmaster}
                        columns={columns} /> : <TableLoader/>}
                     
                    </div>
                  </div>
                </CardBody>
                {/* </Card> */}
              </Col>
            </Row>
            {isRight && (
              <QuestionMasterOffCanvas
                is_Right={isRight}
                toggleRightCanvas={toggleRightCanvas}
                qm_guid={gu_id}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default QuestionMaster;
