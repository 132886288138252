//New Header edited by piyush sir
import React, { useState } from "react";
import { Link } from "react-router-dom";
import avatar from "../../assets/images/users/avatar-1.jpg";
//Import Icons
import FeatherIcon from "feather-icons-react";
import { Dropdown, DropdownMenu, DropdownToggle, Col } from "reactstrap";
import { Navigate } from "react-router-dom";
// Reactstrap
// import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
// import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import talentiq_logo from "../../assets/images/talentiq-logo.svg";

const Header = (props) => {
  let role = localStorage.getItem("userRole");
  let user_name = localStorage.getItem("loggedInUserName");
  const [menu, setMenu] = useState(false);

  function on_click_sign_out() {
    localStorage.setItem("isAuthenticated", false);
    localStorage.setItem("account_short_guid","");
    localStorage.setItem("loggedInUserName","");
    localStorage.setItem("userRole","");
    localStorage.setItem("user_guid","");
    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  }
  const [search, setsearch] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);
  const [isClick, setClick] = useState(true);
  const getInitials = (fullName) => {
    return fullName?.split(' ').map((name) => name[0]).join('').toUpperCase();
  };
  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box bg-light">
              <Link to="/dashboard" className="logo logo-dark">
                {/* <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span> */}
                <span className="logo-lg">
                  <img src={talentiq_logo} alt="" height="40" width="150" />
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                /> */}
                {/* <button className="btn btn-primary" type="button">
                  <i className="bx bx-search-alt align-middle"></i>
                </button> */}
              </div>
            </form>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FeatherIcon icon="search" className="icon-lg" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Search Result"
                      />

                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}

            {/* light / dark mode */}
            {/* <LightDark layoutMode={props['layoutMode']} onChangeLayoutMode={onChangeLayoutMode} /> */}

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <FeatherIcon
                  icon="grid"
                  className="icon-lg"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="p-2">
                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>
 
                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            {/* <NotificationDropdown /> */}
            {/* <div
              onClick={() => {
                dispatch(showRightSidebarAction(!showRightSidebar));
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <FeatherIcon
                  icon="settings"
                  className="icon-lg"
                />
              </button>
            </div> */}
            {/* <ProfileMenu /> */}
            {/* //profile Menu///////////////////////////////////////////////////// */}
            <Dropdown
              isOpen={menu}
              toggle={() => setMenu(!menu)}
              className="d-inline-block me-4"
            >
              <DropdownToggle
                className="btn header-item bg-soft-light"
                // className="btn header-item bg-soft-light border-start border-end"
                id="page-header-user-dropdown"
                tag="button"
              >
                <div className="d-flex">
                  {/* <img
                    className="me-2 avatar-md rounded-circle img-thumbnail"
                    src={avatar}
                    alt="Header Avatar"
                  /> */}
                  {/* <span className="avatar-title me-2 bg-secondary-subtle text-light display-5 m-0 rounded-circle avatar-lg">
                    <i className="bx bxs-user-circle"></i>
                    {getInitials(user_name)}
                  </span> */}
                   <div className="avatar-xm mx-auto mb-0">
                        <span className="avatar-title bg-light text-primary font-size-24 p-3 display-3 m-0 rounded-circle" style={{ width: "55px", height: "55px" }}>
                          {getInitials(user_name)}
                        </span>
                      </div>
                  <div className="col-lg-8">
                    <h5 className="mb-0 mt-1 text-primary font-size-16">
                      {user_name}
                    </h5>{" "}
                    <span className="mb-2 text-muted text-uppercase font-size-12 ms-2">
                      {role}
                    </span>
                  </div>

                  {/* <i className="ms-2 mt-3 font-size-20 bx bx-dots-vertical-rounded d-none d-xl-inline-block" /> */}
                  <i className="ms-3 mt-2 font-size-20 mdi mdi-chevron-down d-none d-xl-inline-block" />
                </div>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {/* <DropdownItem tag="a" href="/profile"> */}
                <Link
                  to="/login"
                  onClick={on_click_sign_out}
                  className="dropdown-item"
                >
                  Sign Out{" "}
                </Link>{" "}
              </DropdownMenu>
            </Dropdown>
            {/* //profile menu ended///////////////////////////////////////////////// */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

// Header.propTypes = {
//   changeSidebarType: PropTypes.func,
//   leftMenu: PropTypes.any,
//   showRightSidebar: PropTypes.any,
//   showRightSidebarAction: PropTypes.func,
//   t: PropTypes.any,
//   toggleLeftmenu: PropTypes.func,
//   changelayoutMode: PropTypes.func,
//   layoutMode: PropTypes.any,
// };

// const mapStatetoProps = state => {
//   const {
//     layoutType,
//     showRightSidebar,
//     leftMenu,
//     layoutMode
//   } = state.Layout;
//   return { layoutType, showRightSidebar, leftMenu, layoutMode };
// };

// export default connect(mapStatetoProps, {
//   // showRightSidebarAction,
//   // changelayoutMode,
//   // toggleLeftmenu,
//   // changeSidebarType,
// })(withTranslation()(Header));
export default Header;
