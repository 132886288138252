import React from "react";
import ReactApexChart from "react-apexcharts";
// import Completion from "../../assets/images/checklist.png";

const RadialChart = ({ percentage_val }) => {
  // let totalAssesments = 1100;
  // let completedAssesments = 550;
  // let per_val;
  // //   let per = (val) => (val / total_value) * 100;
  // function percentage(val) {
  //   per_val = (val / totalAssesments) * 100;
  //   return Math.round(per_val);
  // }
  // let compPer = percentage(completedAssesments);
  const series = [percentage_val];

  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 20,
          size: "55%",
          // image: [Completion],
          imageWidth: 40,
          imageHeight: 34,
          imageClipped: false,
        },
        dataLabels: {
          name: {
            show: false,
            color: "#fff",
          },
          value: {
            show: true,
            color: "#333",
            offsetY: 10,
            fontSize: "22px",
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 1,
        gradientToColors: ["#52CEFF"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Assessments Completed"],
    tooltip: {
      enabled: true,
      
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
      style: {
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#FFF", // Change the text color of the tooltip
        background: "#FFFFFF", // Change the background color of the tooltip
      },
    },
    
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="180"
      className="apex-charts"
    />
  );
};

export default RadialChart;
