import React, { useMemo, useState, useEffect } from "react";
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import { Row, Col, CardBody, Input } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import {dateyyyymmdd_tohtml} from '../../helpers/date'
import JobsOffCanvas from "../../components/jobs/jobsOffCanvas";
import CustomTable from "../../common/CustomTable";

//components
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import TableLoader from "../../components/Common/TableLoader";
// import Pagination from "../../components/Common/Pagination"

function Jobs() {
  const handleNameClick = (job_desc) => {
    setJobDesc(job_desc);
    setIsRight(!isRight);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to="#"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => handleNameClick(row.original)}
        >
          {row.original.title}
        </Link>
      ),
    },
    {
      Header: "Company Name",
      accessor: "company",
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Skills Required",
      accessor: "skill_requirements",
      Cell: ({ row }) =>
        row.original.skill_requirements.map((skill, index) => (
          <>
            <span key={index} className="bg-success badge bg-primary me-1">
              {skill}
            </span>
          </>
        )),
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM , YYYY"),
    },

    // Add more columns as needed
  ];
  // const loginResponse = useSelector((state) => state.loginResponse);

  // const [copyData, setCopyData] = useState([])
  const [jobs, setJobs] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);
  const [state, setState] = useState("");
  const [isRight, setIsRight] = useState(false);
  const [jobDesc, setJobDesc] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCounts , setTotalCounts] = useState();
  
  const filteredJobs = useMemo(() => {
    if (!state) {
      return jobs; // Return original data if no filter is applied
    }

    // Filter companies based on the entered search value
    const lowercasedQuery = state.toLowerCase();
    return jobs.filter((rowData) => {
      return Object.values(rowData).some((value) =>
        String(value).toLowerCase().includes(lowercasedQuery),
      );
    });
  }, [jobs, state]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setIsPreloader(true);
       api_get(
      null,
      `${ep.entity}/v1/joblisting/type/prequalified?limit=10&offset=${currentPage}`,
      (_jobs_err, _jobs_res) => {
        // document.getElementById("preloader").style.display = "block" ;

        if (_jobs_res) {
          setIsPreloader(false);

          setJobs(_jobs_res.joblistings);
          // document.getElementById("preloader").style.display = "none";
        }
        else if(_jobs_err){
          setIsPreloader(false);
        }
      },
    );
  }, [currentPage])
  

  useEffect(() => {
    setIsPreloader(true);

    api_get(
      null,
      ep.entity + "/v1/joblisting/type/prequalified?limit=10",
      (_jobs_err, _jobs_res) => {
        // document.getElementById("preloader").style.display = "block" ;
          setTotalCounts(_jobs_res.total_data)
        if (_jobs_res) {
          setIsPreloader(false);

          setJobs(_jobs_res.joblistings);
          // document.getElementById("preloader").style.display = "none";
        }
        else if(_jobs_err){
          setIsPreloader(false);
        }
      },
    );
  }, []);
  // useEffect(() => {
  //   if (isPreloader === true) {
  //     document.getElementById("preloader").style.display = "block";
  //   } else {
  //     document.getElementById("preloader").style.display = "none";
  //   }
  // }, [isPreloader]);
  // const on_click_job_title = () => {};

  //meta title
  // document.title = "Responsive Table | Minia - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="hstack gap-3 mb-2 mt-0">
            {/* <Input onChange={(e) => setState(e.target.value)} className="form-control me-auto" type="text" style={{width:'30%' ,marginLeft:'729px'}} placeholder="Search records here..." /> */}
            <Breadcrumbs  title="Jobs" breadcrumbItem="Jobs" />

          </div>

          <Row>
            <Col>
              {/* <Card> */}

              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    {filteredJobs.length > 0 ?  <CustomTable
                    totalCount={totalCounts}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                     data={filteredJobs}
                      columns={columns} /> : <TableLoader/>}
                   
                  </div>
                </div>
              </CardBody>
              {/* </Card> */}
            </Col>
          </Row>
          {/* <div className="d-flex flex-row-reverse" style={{marginLeft:'80%'}}><TablePagination/></div> */}
          {isRight && (
            <JobsOffCanvas
              is_Right={isRight}
              toggleRightCanvas={handleNameClick}
              job_desc={jobDesc}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Jobs;
